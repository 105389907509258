/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import DefaultLayout from './../layouts/Default'
import SEO from './../components/Seo'
import config from './../data/SiteConfig'
import { StaticQuery, graphql, Link } from 'gatsby'
import Icon from './../components/Icon'
import FeaturedClients from './../components/FeaturedClients'

/**
 * The services page.
 */
class ServicesPage extends Component {
  render () {
    return (
      <DefaultLayout className={`page-services`}>
        <SEO
          pageType={`webpage`}
          pageTitle={`Software services`}
          fullPageTitle={`Consultancy, programming, project management and technical writing services`}
          pageDescription={`Technical services offered by Keith Mifsud an independent Software Engineer. Keith provides freelance software development services, freelance software projects consultancy, freelance technical writing services and remote project management.`}
          pageImage={config.siteImage}
          pagePath={'/services'}
        />
        <div className="container pt-10 pt-lg-12 intro">
          <h1>Software services</h1>
          <div className="row">
            <div className="col-12 col-lg-7 pt-5">
              <p className={`lead`}>Over the past thirteen years, I've had the pleasure to work with numerous clients in
                varying sectors and industries, including financial services, automobile, legal, mechanical and other
                information technology sectors.</p>
              <p>I work remotely and on a freelance basis. My timezone allows me to work with clients across the globe
                from the United States, Europe and Australia with at least three hours per day of online availability.</p>
              <div className="row pt-5 text-center text-lg-left">
                <div className="col-12">
                  <Link to="/contact" className={`btn btn-warning btn-lg`}>
                    Let's work together
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 offset-lg-1">
              <StaticQuery query={graphql`
            query {
              allMarkdownRemark (
                sort: {fields: [frontmatter___order], order: ASC}
                filter: {
                  fields:{
                    collection: {eq: "services"}
                  }
                }
            ){
                edges{
                  node{
                    id
                    frontmatter{
                      description
                      title
                      path
                      icon
                      order
                    }
                  }
                }
              }
            }
                `}
                           render={data =>
                             <div className={`row`}>
                               <div className="col-12 pt-lg-3 pt-8 pb-lg-0 pb-3">
                                 <h2>Explore services</h2>
                               </div>
                               {data.allMarkdownRemark.edges.map(({ node }, index, arr) => (
                                 <div className="col-12 service-name pb-lg-3 pb-5 pl-lg-0 pl-5" key={index}>
                                   <Icon type={node.frontmatter.icon}/>
                                   <Link to={node.frontmatter.path} className={`hover-none hover-lighten`}>
                                     <h3 className={`pl-3 hover-lighten`}>
                                       {node.frontmatter.title}
                                       </h3>
                                   </Link>
                                 </div>
                               ))}
                             </div>
                           }
              />
            </div>
          </div>
        </div>
        <section className="featured-client-logos">
          <div className="container">
            <div className="row pt-lg-10 pt-5">
              <div className="col-12">
                <h2>Recent clients</h2>
              </div>
            </div>
          </div>
          <div className="bg-white border-bottom border-top ">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <FeaturedClients/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </DefaultLayout>
    )
  }
}

export default ServicesPage
